import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment/moment'
import Select from 'react-select'
import { addArrayMyApps, resetMyApps } from '../../store/myAppsReducer'
import { updateMyApp } from '../../store/myAppsReducer'
import { getAvailableApps, updateAppToFree } from '../../http/appsApi'
import Modal from '../Modal/Modal'
import android from '../../img/android.png'
import ios from '../../img/apple.png'
import globus from '../../img/globus.png'
import TooltipImage from '../Tooltip/TooltipImage'
import './MyApps.css'


const MyApps = () => {
    const dispatch = useDispatch()
    const myAppsStore = useSelector(state => state.myApps.myApps)
    const userStore = useSelector(state => state.user.user)
    const [showTooltip, setShowTooltip] = useState(false)
    // const appsInWork = appsStore.filter(el => Object.values(el.sources).flat().length !== 0)
    const [modalActive, setModalActive] = useState(false)
    const [findApp, setFindApp] = useState({})
    const [selectedSource, setSelectedSource] = useState([])
    const [sources, setSources] = useState([])

    useEffect( () => {
        dispatch(resetMyApps())
        getAvailableApps().then(data => {
            if (data.length !== 0) {
                const apps = data.filter(el => Object.values(el.sources).flat().length !== 0)
                const appsWeb = apps.filter(el => Object.values(el.sources).flat().includes(userStore.webName))
                dispatch(addArrayMyApps(appsWeb))
            } 
        })
    }, [])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    // const appsByWeb = appsInWork.filter(el => Object.values(el.sources).flat().includes(userStore.webName))

    const findSource = (obj) => {
        const entries = Object.entries(obj)
        
        return entries.map((item) => {     
            if (item[1].includes(userStore.webName)) {
                return `${item[0]} `
            } else {
                return null
            }
        })
    }

    const modalHandler = (e) => {
        setModalActive(true)
        const app = myAppsStore.find(app => app._id === e.target.id)
        setFindApp(app)

        const appSources = Object.entries(app.sources).map(element => {
            if (element[1].includes(userStore.webName)) {
                return element[0]
            }
        })

        const modifySources = appSources.map(element => {
            return {
                value: element,
                label: element,
                color: '#000'
            }
        })
        setSources(modifySources.filter(el => el.value !== undefined))
    }

    const toFreeHandler = () => {
        updateAppToFree(findApp._id, userStore.webName, selectedSource).then(resp => {
            if (resp.message) {
                dispatch(updateMyApp(resp.app))
                alert(`Приложение ${findApp.appName} освобождено под ${selectedSource}`)
            } else {
                alert(`Ошибка`)
            }
        })
    }

    const handleMouseEnter = () => {
        setShowTooltip(true)
    }
    
      const handleMouseLeave = () => {
        setShowTooltip(false)
    }

    return (
        <div className={'form'}>
            {/* {userStore.role === 'TechHelper' || userStore.role === 'CreoUnit' || userStore.role === 'CreoUnitHelper' ?
                <h3>Нет доступа</h3>
            :
                <> */}
                    <table className={'table'}>
                        <tbody>
                            <tr>
                                <td>Всего приложений в работе</td>
                                <td>{myAppsStore.length}</td>
                            </tr>
                            
                        </tbody>
                    </table>

                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>App</th>
                                <th>ID</th>
                                <th>OS</th>
                                <th>Source</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myAppsStore.map(item => {
                                return (
                                    <tr>
                                        <td>{item.appName}</td>
                                        <td>{item.partner}</td>
                                        <td>{item.platform}</td>
                                        <td>{findSource(item.sources)}</td>
                                        <td>
                                            <button id={item._id} onClick={modalHandler} className={'webBtnMyAppsEdit'}>Info</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {modalActive && (
                        <section className={'section'}>
                            <Modal 
                                active={modalActive}
                                setActive={setModalActive}>
                                    <div className={/*'popupContentMyApps'*/'wrapTest'}>
                                        <TooltipImage item={findApp} />
                                        <div className={/*'discriptionMyAppsPopup'*/'discriptionAppsTest'}>
                                            <div className={/*'wrapperMyApps'*/'wrapperTestMyApps'}>
                                                <div className={'appNameTest'}>{findApp.appName}
                                                    <div className={'iconWrapTest'}>
                                                        <img 
                                                            alt = ''
                                                            className={'iconTest'} 
                                                            src={findApp.platform === 'android' ? android : ios}
                                                        />
                                                        <div 
                                                            onMouseEnter={handleMouseEnter} 
                                                            onMouseLeave={handleMouseLeave}
                                                            style={{ position: 'relative', display: 'inline-block' }}>
                                                                <img 
                                                                    alt = ''
                                                                    className={'iconTest'} 
                                                                    src={globus}
                                                                />
                                                                {showTooltip && findApp.geos && (
                                                                    <div 
                                                                        className="tooltip"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            transform: 'translateX(-50%)',
                                                                            backgroundColor: '#333',
                                                                            color: '#fff',
                                                                            padding: '5px',
                                                                            borderRadius: '4px',
                                                                            fontSize: '14px',
                                                                            maxWidth: '300px',
                                                                            whiteSpace: 'normal',
                                                                            overflowWrap: 'break-word',
                                                                            zIndex: 1000
                                                                        }}
                                                                    >
                                                                        {findApp.geos.split(' ').map((geo, index) => (
                                                                            <small key={index}style={{ marginRight: '5px' }}>
                                                                                {geo}
                                                                            </small>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={/*'wrapperMyApps'*/'wrapperTest'}>
                                                <div className={'discription_mainTest'}>Partner ID: {findApp.partner}</div>
                                                <div className={'title_mainTest'}>{findApp.age}</div>
                                                <div className={/*'discription_mainMyApps'*/'titleTest'}>Release: {moment(findApp.release_date).format("hh:mm:ss DD.MM.YYYY")}</div>
                                                <div className={/*'discription_mainMyApps'*/'titleTest'}><a className={'link_tg'} href={findApp.link} target="_blank" rel="noopener noreferrer">Link</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={/*'wrapperMyApps'*/'wrapperr'}>
                                        {findApp.commonApp && (
                                            <div className={/*'discription_mainMyApps'*/'titleTest'}>Common App for IronSource</div>
                                        )}
                                        <div className={/*'discription_mainMyApps'*/'titleTest'}>Naming: {findApp.naming}</div>
                                        <div className={/*'discription_mainMyApps'*/'titleTest'}>Sources in work:</div>
                                        <div className={/*'textMyApps'*/'textTest'}>
                                            <Select styles={colorStyles} menuPortalTarget={document.body} className={'smallOptionsTest'} options={sources} onChange={selectedOption => setSelectedSource(selectedOption.value)} />
                                        </div>
                                        {selectedSource.length !== 0 && (
                                            <button className={'webBtnTestMyApps'} onClick={toFreeHandler}>Освободить {findApp.appName} с источника {selectedSource}</button>
                                        )}
                                    </div>
                            </Modal>
                        </section>
                    )}
                {/* </>
            } */}
        </div>
    );
}

export default MyApps
