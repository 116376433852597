const defaultState = {
    myApps: []
}

const RESET_MY_APPS = 'RESET_MY_APPS'
const ARRAY_MY_APPS = 'ARRAY_MY_APPS'
const UPDATE_MY_APP = 'UPDATE_MY_APP'

export const myAppsReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_MY_APPS: 
            return {
                ...state, myApps: []
            }
        case ARRAY_MY_APPS:
            return { 
                ...state,
                myApps: (action.myApps).concat(state.myApps)
            }
        case UPDATE_MY_APP:
            return {
                ...state, myApps: state.myApps.map(el => el._id === action.myApp._id ? {
                    ...el,
                        sources: action.myApp.sources,
                }
                : el)
            }
        default:
            return state
    }
}
export const resetMyApps = () => ({type: RESET_MY_APPS})
export const addArrayMyApps = (myApps) => ({type: ARRAY_MY_APPS, myApps})
export const updateMyApp = (myApp) => ({type: UPDATE_MY_APP, myApp})