const defaultState = {
    allUsers: []
}

const RESET_ALL_USERS = 'RESET_ALL_USERS'
// const ADD_USER = 'ADD_USER'
const ARRAY_USERS = 'ARRAY_USERS'

export const allUsersReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_USERS: 
            return {
                ...state, allUsers: []
            }
        // case ADD_USER:
        //     return {
        //         ...state, allUsers: state.users.concat(action.user)
        //     }
        case ARRAY_USERS:
            return { 
                ...state,
                allUsers: (action.allUsers).concat(state.allUsers)
            }
        default:
            return state
    }
}
export const resetUsers = () => ({type: RESET_ALL_USERS})
// export const addUser = (user) => ({type: ADD_USER, user})
export const addArrayUsers = (allUsers) => ({type: ARRAY_USERS, allUsers})