import React from 'react'
// import { useNavigate } from 'react-router-dom'
// import { PROJECTS_ROUTE, TASKS_ROUTE} from '../../utils/consts'

import './Loader.css'

const Loader = () => {

  return (
    <span className={'loader'}></span>
    // <div className={'lds__roller'}>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    // </div>
  )
}

export default Loader