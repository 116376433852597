import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'


import AppRouter from './components/AppRouter.js'
import { changeIsAuth } from './store/authReducer.js'
import { check } from './http/usersApi.js'
import Loader from './components/Loader.js'
import NavBar from './components/NavBar/NavBar.jsx'

const App = () => {
  const dispatch = useDispatch()
  const userStore = useSelector(state => state.user.user)
  const [loading, setLoading] = useState(true)

  const checking = useCallback(() => {
    check().then(data => {
      dispatch(changeIsAuth(true))
    }).finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    checking()
  }, [checking])

  const currentPath = window.location.pathname

  return (
    <BrowserRouter>
      {loading ?
        <div className={'list'}>
          <Loader />
        </div>
      :
        <>
          {currentPath !== '/login' && (
            <NavBar />
          )}
          <AppRouter />
        </>
      }
    </BrowserRouter>
  )

}

export default App