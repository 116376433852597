import React from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'

export const SidebarData = [
  // {
  //   title: 'Home',
  //   path: '/home',
  //   icon: <AiIcons.AiFillHome />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Applications',
    path: '/appsList',
    icon: <FaIcons.FaChevronRight />/*<IoIcons.IoIosPaper />*/,
    cName: 'nav-text',
  },
  {
    title: 'My Apps',
    path: '/myapps',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Creatives',
    path: '/creoList',
    icon: <FaIcons.FaChevronRight />/*<FaIcons.FaCartPlus />*/,
    cName: 'nav-text',
  },
  {
    title: 'My Creos',
    path: '/mycreos',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Ready Creos',
    path: '/readycreos',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Creo Templates',
    path: '/creoTemplates',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Geo Pool',
    path: '/geopool',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  // {
  //   title: 'Interactives',
  //   path: '/interactiveList',
  //   icon: <FaIcons.FaChevronRight />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Log out',
    path: '/login',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text',
  },
]

export const SidebarDataTechHelper = [
  {
    title: 'Applications',
    path: '/appsList',
    icon: <FaIcons.FaChevronRight />/*<IoIcons.IoIosPaper />*/,
    cName: 'nav-text',
  },

  {
    title: 'Add an App',
    path: '/form',
    icon: <AiIcons.AiOutlineAppstoreAdd />,
    cName: 'nav-text',
  },

  {
    title: 'Update an App',
    path: '/updateApp',
    icon: <AiIcons.AiOutlineAppstoreAdd />,
    cName: 'nav-text',
  },

  {
    title: 'Delete an App',
    path: '/delete',
    icon: <AiIcons.AiOutlineDelete />,
    cName: 'nav-text',
  },

  // {
  //   title: 'Move an App',
  //   path: '/moving',
  //   icon: <AiIcons.AiOutlineDoubleRight />,
  //   cName: 'nav-text',
  // },

  {
    title: 'Stats',
    path: '/info',
    icon: <AiIcons.AiOutlineBook />,
    cName: 'nav-text',
  },
  
  {
    title: 'Log out',
    path: '/login',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text',
  }
]

export const SidebarDataCreoLead = [
  // {
  //   title: 'Home',
  //   path: '/home',
  //   icon: <AiIcons.AiFillHome />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Applications',
    path: '/appsList',
    icon: <FaIcons.FaChevronRight />/*<IoIcons.IoIosPaper />*/,
    cName: 'nav-text',
  },
  {
    title: 'My Apps',
    path: '/myapps',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Creatives',
    path: '/creoList',
    icon: <FaIcons.FaChevronRight />/*<FaIcons.FaCartPlus />*/,
    cName: 'nav-text',
  },
  {
    title: 'My Creo',
    path: '/mycreos',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Ready Creos',
    path: '/readycreos',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Add Creo',
    path: '/creo',
    icon: <AiIcons.AiOutlineAppstoreAdd />,
    cName: 'nav-text',
  },
  // {
  //   title: 'Add Interactive',
  //   path: '/interactive',
  //   icon: <FaIcons.FaChevronRight />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Creo Templates',
    path: '/creoTemplates',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Geo Pool',
    path: '/geopool',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  // {
  //   title: 'Interactives',
  //   path: '/interactiveList',
  //   icon: <FaIcons.FaChevronRight />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Stats',
    path: '/info',
    icon: <AiIcons.AiOutlineBook />,
    cName: 'nav-text',
  },

  {
    title: 'Creo Profits',
    path: '/creoProfits',
    icon: <AiIcons.AiOutlineBook />,
    cName: 'nav-text',
  },
  
  {
    title: 'Log out',
    path: '/login',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text',
  }
]

export const SidebarDataLead = [
  // {
  //   title: 'Home',
  //   path: '/',
  //   icon: <AiIcons.AiFillHome />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Applications',
    path: '/appsList',
    icon: <FaIcons.FaChevronRight />/*<IoIcons.IoIosPaper />*/,
    cName: 'nav-text',
  },
  {
    title: 'My Apps',
    path: '/myapps',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Creatives',
    path: '/creoList',
    icon: <FaIcons.FaChevronRight />/*<FaIcons.FaCartPlus />*/,
    cName: 'nav-text',
  },
  {
    title: 'My Creo',
    path: '/mycreos',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Ready Creos',
    path: '/readycreos',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  {
    title: 'Creo Templates',
    path: '/creoTemplates',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  // {
  //   title: 'Interactives',
  //   path: '/interactiveList',
  //   icon: <FaIcons.FaChevronRight />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Geo Pool',
    path: '/geopool',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  // {
  //   title: 'Interactives',
  //   path: '/interactiveList',
  //   icon: <FaIcons.FaChevronRight />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Stats',
    path: '/info',
    icon: <AiIcons.AiOutlineBook />,
    cName: 'nav-text',
  },

  {
    title: 'Creo Profits',
    path: '/creoProfits',
    icon: <AiIcons.AiOutlineBook />,
    cName: 'nav-text',
  },
  {
    title: 'Log out',
    path: '/login',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text',
  }
]

export const SidebarDataAdmin = [
  // {
  //   title: 'Home',
  //   path: '/home',
  //   icon: <AiIcons.AiFillHome />,
  //   cName: 'nav-text',
  // },
  {
    title: 'Applications',
    path: '/appsList',
    icon: <FaIcons.FaChevronRight />/*<IoIcons.IoIosPaper />*/,
    cName: 'nav-text',
  },

  {
    title: 'My Apps',
    path: '/myapps',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },

  {
    title: 'Add an App',
    path: '/form',
    icon: <AiIcons.AiOutlineAppstoreAdd />,
    cName: 'nav-text',
  },

  {
    title: 'Update an App',
    path: '/updateApp',
    icon: <AiIcons.AiOutlineAppstoreAdd />,
    cName: 'nav-text',
  },

  // {
  //   title: 'Add Interactive',
  //   path: '/interactive',
  //   icon: <FaIcons.FaChevronRight />,
  //   cName: 'nav-text',
  // },

  {
    title: 'Creatives',
    path: '/creoList',
    icon: <FaIcons.FaChevronRight />/*<FaIcons.FaCartPlus />*/,
    cName: 'nav-text',
  },

  {
    title: 'My Creo',
    path: '/mycreos',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },

  {
    title: 'Ready Creos',
    path: '/readycreos',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },

  {
    title: 'Creo Templates',
    path: '/creoTemplates',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },

  // {
  //   title: 'Interactives',
  //   path: '/interactiveList',
  //   icon: <FaIcons.FaChevronRight />,
  //   cName: 'nav-text',
  // },

  {
    title: 'Geo Pool',
    path: '/geopool',
    icon: <FaIcons.FaChevronRight />,
    cName: 'nav-text',
  },
  
  {
    title: 'Stats',
    path: '/info',
    icon: <AiIcons.AiOutlineBook />,
    cName: 'nav-text',
  },

  {
    title: 'Creo Profits',
    path: '/creoProfits',
    icon: <AiIcons.AiOutlineBook />,
    cName: 'nav-text',
  },

  {
    title: 'Move an App',
    path: '/moving',
    icon: <AiIcons.AiOutlineDoubleRight />,
    cName: 'nav-text',
  },

  {
    title: 'Delete an App',
    path: '/delete',
    icon: <AiIcons.AiOutlineDelete />,
    cName: 'nav-text',
  },

  {
    title: 'Admin Panel',
    path: '/admin',
    icon: <AiIcons.AiOutlineCodepen />,
    cName: 'nav-text',
  },

  {
    title: 'Log out',
    path: '/login',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text',
  }
]

export const SidebarDataCreoUnit = [
  {
    title: 'Creatives',
    path: '/creoList',
    icon: <FaIcons.FaChevronRight />/*<FaIcons.FaCartPlus />*/,
    cName: 'nav-text',
  },
  {
    title: 'Add Creo',
    path: '/creo',
    icon: <AiIcons.AiOutlineAppstoreAdd />,
    cName: 'nav-text',
  },

  // {
  //   title: 'Creo Profits',
  //   path: '/creoProfits',
  //   icon: <AiIcons.AiOutlineBook />,
  //   cName: 'nav-text',
  // },

  {
    title: 'Log out',
    path: '/login',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text',
  }
]

export const SidebarDataCreoUnitHelper = [
  {
    title: 'Creatives',
    path: '/creoList',
    icon: <FaIcons.FaChevronRight />/*<FaIcons.FaCartPlus />*/,
    cName: 'nav-text',
  },

  {
    title: 'Log out',
    path: '/login',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text',
  }
]


