import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { LOGIN_ROUTE, NO_ACCESS_ROUTE } from '../utils/consts.js'

const PrivateRoute = ({ element: Component, allowedRoles, ...rest }) => {
  const userStore = useSelector(state => state.user.user)

  if (allowedRoles && !allowedRoles.includes(userStore.role)) {
    return <Navigate to = {NO_ACCESS_ROUTE} />
  }

  return <Component {...rest} />
}

export default PrivateRoute