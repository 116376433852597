import { React } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const Home = () => {
  const user = useSelector(state => state.user.user)

  return (
    <div className={'content'}>
      <div>
        {user.webName}
      </div>
    </div>
  )
}

export default Home
