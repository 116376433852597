const defaultState = {
    filteredInteractives: []
}

const RESET_ALL_INTERACTIVES = 'RESET_ALL_INTERACTIVES'
const FILTERED_INTERACTIVES = 'FILTERED_INTERACTIVES'

export const filteredInteractivesReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_INTERACTIVES: 
            return {
                ...state, filteredInteractives: []
            }
        case FILTERED_INTERACTIVES:
            return { 
                ...state,
                filteredIteractives: (action.filteredInteractives).concat(state.filteredInteractives)
            }
        default:
            return state
    }
}
export const resetFilteredInteractives = () => ({type: RESET_ALL_INTERACTIVES})
export const addFilteredInteractives = (filteredInteractives) => ({type: FILTERED_INTERACTIVES, filteredInteractives})