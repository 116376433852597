const defaultState = {
    apps: []
}

const RESET_ALL_APPS = 'RESET_ALL_APPS'
const ADD_APP = 'ADD_APP'
const ARRAY_APPS = 'ARRAY_APPS'
const HIDE_APPS = 'HIDE_APPS'
const DELETE_APP = 'DELETE_APP'
const UPDATE_APP = 'UPDATE_APP'

export const appsReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_APPS: 
            return {
                ...state, apps: []
            }
        case ADD_APP:
            return {
                ...state, apps: state.apps.concat(action.app)
            }
        case ARRAY_APPS:
            return { 
                ...state,
                apps: (action.apps).concat(state.apps)
            }
        case HIDE_APPS:
            return { 
                ...state,
                apps: state.apps.filter(item => (action.apps).every(el => el != item.partner))
            }
        case DELETE_APP:
            return {
                ...state, apps: state.apps.filter(el => el.id != action.id)
            }
            case UPDATE_APP:
                return {
                    ...state, apps: state.apps.map(el => el.id === action.app.id ? {
                        ...el,
                            sources: action.app.sources,
                    }
                    : el)
                }
        default:
            return state
    }
}
export const resetApps = () => ({type: RESET_ALL_APPS})
export const addApp = (app) => ({type: ADD_APP, app})
export const addArrayApps = (apps) => ({type: ARRAY_APPS, apps})
export const hideApps = (apps) => ({type: HIDE_APPS, apps})
export const deleteApp = (id) => ({type: DELETE_APP, id})
export const updateApp = (app) => ({type: UPDATE_APP, app})