import {$authHost} from './index.js'

export const addSource = async (sourceName, sourceLabel, mode) => {
    const {data} = await $authHost.post('/api/admin/addNewSource', {sourceName, sourceLabel, mode})
    return data
}

export const addPartner = async (partnerName, naming) => {
    const {data} = await $authHost.post('/api/admin/addNewPartner', {partnerName, naming})
    return data
}

export const addWeb = async (username, webName) => {
    const {data} = await $authHost.post('/api/admin/addNewWeb', {username, webName})
    return data
}

export const getSources = async () => {
    const {data} = await $authHost.get('/api/admin/sources')
    return data
}

export const getPartners = async () => {
    const {data} = await $authHost.get('/api/admin/partners')
    return data
}

export const getAllPartners = async () => {
    const {data} = await $authHost.get('/api/admin/allPartners')
    return data
}

export const getAges = async () => {
    const {data} = await $authHost.get('/api/admin/ages')
    return data
}

export const getPlatforms = async () => {
    const {data} = await $authHost.get('/api/admin/platforms')
    return data
}

export const removeSource = async (sourceName) => {
    const {data} = await $authHost.put('/api/admin/removeSourceFromAllApps', {sourceName})
    return data
}

export const removePartner = async (partnerName) => {
    const {data} = await $authHost.delete('/api/admin/removePartner/' + partnerName)
    return data
}

export const hidePartner = async (partnerName) => {
    const {data} = await $authHost.post('/api/admin/hideAppsAndPartners', {partnerName})
    return data
}

export const addWebToApp = async (_id, appName, web, source) => {
    const {data} = await $authHost.put('/api/admin/addWebToApp/' + _id, {appName, web, source})
    return data
} 

export const removeWebFromApp = async (_id, web, source) => {
    const {data} = await $authHost.put('/api/admin/removeWebFromApp/' + _id, {web, source})
    return data
} 