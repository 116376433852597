import { React, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import {useTg} from '../hooks/useTg'
import { addArrayApps, resetApps } from '../../store/appsReducer'
import {addApp, getApps} from '../../http/appsApi'
import './Form.css'
import { getAges, getPartners, getPlatforms, getSources } from '../../http/adminApi'

const Form = () => {
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const [appName, setAppName] = useState('')
    const [appLink, setAppLink] = useState('')
    const [appPartner, setAppPartner] = useState('')
    const [appAge, setAppAge] = useState('')
    const [appPlatform, setAppPlatform] = useState('')
    const [appImg, setAppImg] = useState({})
    const [agesOptions, setAgesOptions] = useState([])
    const [appGeos, setAppGeos] = useState('')
    const [appSources, setAppSources] = useState([])
    const [appIronAcc, setAppIronAcc] = useState('')
    const [partnersOptions, setPartnersOptions] = useState([])
    const [platformOptions, setPlatformOptions] = useState([])
    const [sourcesOptions, setSourcesOptions] = useState([])
    
    const {tg, queryId} = useTg()

    const test = async (e) => {
        const file = e.target.files[0]
        setAppImg(file)
    }

    const onSendData = useCallback(() => {
        if (userStore.role === 'TechHelper' || userStore.role === 'Admin') {
            if (appName === '' || appLink === '' || appPartner === '' || appAge === '' || appSources.length === 0 || appPlatform === '') {
                alert('Заполни все поля')
            } else {
                addApp(appPartner, appName, appLink, appAge, appGeos, appSources, appPlatform, appIronAcc, appImg).then(data => {
                    data.action = 'add'
                    if (data.message) {
                        alert('Ошибка, такое приложение уже добавлено')
                    } else {
                        tg.sendData(JSON.stringify(data))
                        alert('Приложение добавлено в бот')
                    }
                })
                alert('Успешно')
            }
        } else {
            alert('Нет доступа')
        }
       

    }, [appName, appLink, appPartner, appAge, appGeos, appSources, appPlatform, appImg])

    useEffect(() => {
        const getAgesFromDB = async () => {
            const data = await getAges().then(ages => {
                const modifySources = ages.map(element => {
                    return {
                        value: element.value,
                        label: element.value,
                        color: '#000'
                    }
                })
                setAgesOptions(modifySources)
            })
        }
        const getPartnersFromDB = async () => {
            const data = await getPartners().then(partners => {
                const modifySources = partners.map(element => {
                    return {
                        value: element.name,
                        label: element.name,
                        color: '#000'
                    }
                })
                setPartnersOptions(modifySources)
            })
        }
        const getPlatformsFromDB = async () => {
            const data = await getPlatforms().then(platforms => {
                const modifySources = platforms.map(element => {
                    return {
                        value: element.value,
                        label: element.label,
                        color: '#000'
                    }
                })
                setPlatformOptions(modifySources)
            })
        }
        const getSourcesFromDB = async () => {
            const data = await getSources().then(sources => {
                const modifySources = sources.map(element => {
                    return {
                        value: element.name,
                        label: element.label,
                        color: '#000'
                    }
                })
                setSourcesOptions(modifySources)
            })
        }
        getAgesFromDB()
        getPartnersFromDB()
        getPlatformsFromDB()
        getSourcesFromDB()
    }, [])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        }
    }

    const changeAppName = (e) => {
        setAppName(e.target.value)
    }

    const changeAppLink = (e) => {
        setAppLink(e.target.value)
    }

    const changeAppGeos = (e) => {
        setAppGeos(e.target.value)
    }

    // const changeAppSources = (e) => {
    //     setSourcesOptions(e.target.value)
    // }

    const changeAppIronAcc = (e) => {
        setAppIronAcc(e.target.value.toUpperCase())
    }

  return (
    <div className={'form'}>
        {/* {userStore.role === 'Admin' || userStore.role === 'TechHelper' ?
            <> */}
                <h3>Введите название приложения</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Имя приложения'}
                    value={appName}
                    onChange={changeAppName}
                />
                <h3>Картинка</h3>
                <input 
                    accept="image/*" 
                    className={'input'}
                    id="icon-button-file"  
                    type="file"
                    onChange={e => test(e)}
                />
                <h3>Введите ссылку на приложение</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Ссылка на прилу'}
                    value={appLink}
                    onChange={changeAppLink}
                />
                <h3>Выбери поставщика</h3>
                <span className={'text'}>
                    <Select 
                        styles={colorStyles}
                        className={'input'} 
                        options={partnersOptions} 
                        onChange={(e) => setAppPartner(e.value)}
                        placeholder={'Поставщик'} 
                    />
                </span>
                <h3>Выбери возраст</h3>
                <span className={'text'}>
                    <Select 
                        styles={colorStyles}
                        className={'input'} 
                        options={agesOptions} 
                        onChange={(e) => setAppAge(e.value)}
                        placeholder={'Возраст'} 
                    />
                </span>
                <h3>Введите доступные гео</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Для ios приложений и приложение trident поле оставить пустым'}
                    value={appGeos}
                    onChange={changeAppGeos}
                />
                <h3>Выбери доступные источники приложения</h3>
                <span className={'text'}>
                <Select 
                    styles={colorStyles}
                    className={'input'}
                    options={sourcesOptions}
                    onChange={selectedOptions => setAppSources(selectedOptions ? selectedOptions.map(option => option.value) : [])} 
                    isMulti
                    placeholder={'Источники'}
                    value={sourcesOptions.filter(option => appSources.includes(option.value))}
                />
                </span>
                {appSources.includes('ironsource') && (
                    <div>
                        <h3>Впиши аккаунт, для залива на приложение, если оно будет общим для IronSource</h3>
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Например IS9'}
                            value={appIronAcc}
                            onChange={changeAppIronAcc}
                        />
                    </div>
                )}
                <h3>Выбери ОС приложения</h3>
                <span className={'text'}>
                    <Select 
                        styles={colorStyles}
                        className={'input'} 
                        options={platformOptions} 
                        onChange={(e) => setAppPlatform(e.value)}
                        placeholder={'ОС'} 
                    />
                </span>
                <button className={'webBtnAdd'} onClick={onSendData}>Добавить</button>
            {/* </> 
        :
            <h3>Нет доступа</h3>
        } */}
    </div>
  );
}

export default Form

