const defaultState = {
    user: {
        // _id: null,
        // id: null,
        // username: null,
        // first_name: null,
        // last_name: null,
        // webName: null,
        // chatId: null,
        // regDate: null,
        // role: null
    }
}

const ADD_USER = 'ADD_USER'
const RESET_USER = 'RESET_USER'

export const userReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case ADD_USER:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    // _id: action.user._id,
                    // id: action.user.id,
                    username: action.user.username,
                    // first_name: action.user.first_name,
                    // last_name: action.user.last_name,
                    webName: action.user.webName,
                    authorized: action.user.authorized,
                    // chatId: action.user.chatId,
                    // regDate: action.user.regDate,
                    role: action.user.role
                }
            })
        case RESET_USER:
            return Object.assign({}, state, {
                user: {}
            }) 
        default:
            return state
    }
}

export const addOneUser = (user) => ({type: ADD_USER, user})
export const resetUser = () => ({type: RESET_USER})