import { React, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addArrayApps, resetApps } from '../../store/appsReducer'
import Select from 'react-select'
import {useTg} from '../hooks/useTg'
import {updateAppSetup, getApps} from '../../http/appsApi'
import { getAges, getPlatforms } from '../../http/adminApi'
import './Form.css'


const MoveForm = () => {
    const dispatch = useDispatch()
    const appsStore = useSelector(state => state.apps.apps)
    const userStore = useSelector(state => state.user.user)
    const [appName, setAppName] = useState('')
    const findApp = appsStore.find(el => el.appName === appName)

    const [appStatus, setAppStatus] = useState(findApp?.status)
    const [statusChanged, setStatusChanged] = useState(false)
    const [appPartner, setAppPartner] = useState(findApp?.partner)
    const [appWeb, setAppWeb] = useState(findApp?.web)
    const [appLink, setAppLink] = useState(findApp?.link)
    const [appAge, setAppAge] = useState(findApp?.age)
    const [appPlatform, setAppPlatform] = useState(findApp?.platform)
    const [appImg, setAppImg] = useState(findApp?.appImg)
    console.log(findApp)

    const [agesOptions, setAgesOptions] = useState([])
    const [platformOptions, setPlatformOptions] = useState([])

    const [prevAppStatus, setPrevAppStatus] = useState('')

    const {tg, queryId} = useTg()

    const statusOptions = [
        {value: 'Бан', label: 'В БАН', color: '#000'},
        {value: 'Сломанная', label: 'В СЛОМАННЫЕ', color: '#000'},
        {value: 'Можно лить', label: 'МОЖНО ЛИТЬ', color: '#000'},
        {value: 'По запросу', label: 'ПО ЗАПРОСУ', color: '#000'}
    ]

    const onSendData = useCallback(() => {
        if (userStore.role === 'Admin') {
            if (appName === '') {
                alert('Заполни все поля')
            } else {
                const findApp = appsStore.find(el => el.appName === appName)
                updateAppSetup(findApp._id, appPartner, appName, appWeb, appLink, appAge, appStatus).then(data => {
                    data.action = 'update'
                    data.prevAppStatus = prevAppStatus
                    data.statusChanged = statusChanged
                    if (data) {
                        tg.sendData(JSON.stringify(data))
                        alert(`Приложение ${appName} изменено`)
                    } else {
                        alert('Ошибка')
                    }
                })
            }
        } else {
            alert('Нет доступа')
        }
        
    }, [appName, appPartner, appAge, appPlatform, appWeb, appLink, appStatus, prevAppStatus, statusChanged])

    useEffect( () => {
        dispatch(resetApps())
        getApps().then(data => {
            if (data.length !== 0) {
                dispatch(addArrayApps(data))
            } 
        })
    }, [])

    useEffect( () => {
        setAppStatus(findApp?.status)
        setAppPartner(findApp?.partner)
        setAppWeb(findApp?.web)
        setAppLink(findApp?.link)
        setAppAge(findApp?.age)
        setAppPlatform(findApp?.platform)
    }, [appName])

    useEffect(() => {
        const getAgesFromDB = async () => {
            const data = await getAges().then(ages => {
                const modifySources = ages.map(element => {
                    return {
                        value: element.value,
                        label: element.value,
                        color: '#000'
                    }
                })
                setAgesOptions(modifySources)
            })
        }
        const getPlatformsFromDB = async () => {
            const data = await getPlatforms().then(platforms => {
                const modifySources = platforms.map(element => {
                    return {
                        value: element.value,
                        label: element.label,
                        color: '#000'
                    }
                })
                setPlatformOptions(modifySources)
            })
        }
        getAgesFromDB()
        getPlatformsFromDB()
    }, [])


    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        }
    }

    const options = appsStore.map(app => ({ ...app, label: app.appName, value: app.appName, color: '#000' }))

    const appData = appsStore.find(app => app.appName === appName)

    return (
        <div className={'form'}>
            {/* {userStore.role === 'Admin' || userStore.role === 'TechHelper' ?
                <> */}
                    <h3>Выбери приложение</h3>
                    <span className={'text'}>
                        <Select 
                            styles={colorStyles}
                            className={'input'} 
                            options={options} 
                            onChange={(e) => {setAppName(e.value); setPrevAppStatus(e.status)}} 
                            placeholder={'Имя приложения'} 
                        />
                    </span>
                    <h3 className={'title_main'}>Выбери, что хочешь изменить</h3>
                    <span className={'text'}>Имя приложения
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Имя приложения'}
                            value={appName}
                            onChange={(e) => setAppName(e.target.value)}
                        />
                    </span>
                    <span className={'text'}>Статус
                    <div className={'prevData'}>
                        Текущее значение: {prevAppStatus}
                    </div>
                        <Select 
                            styles={colorStyles}
                            className={'input'} 
                            options={statusOptions} 
                            onChange={(e) => {setAppStatus(e.value); setStatusChanged(!statusChanged)}}
                            placeholder={'Статус'}
                        />
                    </span>
                    <span className={'text'}>Поставщик
                    <div className={'prevData'}>
                        Текущее значение: {appData?.partner}
                    </div>
                        {/* <Select 
                            styles={colorStyles}
                            className={'input'} 
                            options={partnerOptions} 
                            onChange={(e) => setAppPartner(e.value)}
                            placeholder={'Поставщик'}
                        /> */}
                    </span>
                    <span className={'text'}>Веб
                    <div className={'prevData'}>
                        Текущее значение: {appData ? Object.entries(appData.sources) : ''}
                    </div>
                        {/* <Select 
                            styles={colorStyles}
                            className={'input'} 
                            options={webOptions} 
                            onChange={(e) => setAppWeb(e.value)}
                            placeholder={'Веб'}
                        /> */}
                    </span>
                    <span className={'text'}>Ссылка на прилу
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Ссылка'}
                            value={appLink}
                            onChange={(e) => setAppLink(e.target.value)}
                        />
                    </span>
                    <span className={'text'}>Возраст
                    <div className={'prevData'}>
                        Текущее значение: {appData?.age}
                    </div>
                        <Select 
                            styles={colorStyles}
                            className={'input'} 
                            options={agesOptions} 
                            onChange={(e) => setAppAge(e.value)}
                            placeholder={'Возраст'}
                        />
                    </span>
                    <span className={'text'}>ОС
                    <div className={'prevData'}>
                        Текущее значение: {appData?.platform}
                    </div>
                        <Select 
                            styles={colorStyles}
                            className={'input'} 
                            options={platformOptions} 
                            onChange={(e) => setAppPlatform(e.value)}
                            placeholder={'OC'}
                        />
                    </span>
                    <button onClick={onSendData}>Перенести</button>
                {/* </>
            :
                <h3>Нет доступа</h3>
            } */}
        </div>
    );
}

export default MoveForm
