import { React, useState } from 'react'
import moment from 'moment/moment'
import './CreoItem.css'
import TooltipVideo from '../Tooltip/TooltipVideo'
import Modal from '../Modal/Modal'

const CreoItem = ({item, className, multiOptionGeo}) => {
    const date = moment(item.release_date).format("DD.MM.YYYY")
    const [modalActive, setModalActive] = useState(false)
    const [tooltipActive, setTooltipActive] = useState(false)

  return (
    <div className={'element ' + className}>
        <div className={'wrapCreo'}>
        <small className={multiOptionGeo.some(el => item.readyGeos.includes(el)) ? 'upClueFree' : 'upClue'}>{item.orderedGeos.length}</small>
            <TooltipVideo item={item} tooltipActive={tooltipActive} setTooltipActive={setTooltipActive} />
            <div className={'discriptionCreo'}>
                <div className={'wrapperCreo'}>
                    <div className={'appNameTest'}>{item.creoName} 
                    </div>
                </div>
                <div className={'wrapperCreo'}>
                    <div className={'discription_mainTest'}>Template: {item.template}</div>
                </div>
                <div className={'wrapperCreo'}>
                    <div className={'titleTest'}>Duration: {item.duration}</div>
                </div>
                <div className={'wrapperCreo'}>
                    <div className={'linkTest'}>Topic: {item.hashes.join('\n')}</div>
                </div>
            </div>
            <button className={'webBtnCreo'} onClick={()=> setModalActive(true)}>Подробнее</button>
        </div>
        {modalActive && (
            <section className={'section'}>
                <Modal 
                    active={modalActive}
                    setActive={setModalActive}>
                        <div className={'popupContent'}>
                            <TooltipVideo item={item} tooltipActive={tooltipActive} setTooltipActive={setTooltipActive} />
                            <div className={'discriptionCreoPopup'}>
                                <div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'appNameTest'}>{item.creoName}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>Template: {item.template}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'titleTest'}>Duration: {item.duration}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'linkTest'}>Topic: <br/> {item.hashes.join(' ')}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>Refs: {item.refers.join(' ')}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>Release: {moment(item.release_date).format("hh:mm:ss DD.MM.YYYY")}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>Formats: {item.formats}</div>
                                    </div>
                                    <div className={'wrapperCreo'}>
                                        <div className={'discription_mainTest'}>My GEO: <br/> {item.myCreoGeos.join(' ')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Modal>
            </section>
        )}
    </div>
  )
}

export default CreoItem
