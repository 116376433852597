import { React, useState } from 'react'
import moment from 'moment/moment'
// import './CreoItem.css'
import TooltipTemplate from '../Tooltip/TooltipTemplate'
import Modal from '../Modal/Modal'

const CreoTemplateItem = ({item, className, multiOptionGeo}) => {
    const date = moment(item.release_date).format("DD.MM.YYYY")
    const [modalActive, setModalActive] = useState(false)
    const [tooltipActive, setTooltipActive] = useState(false)

  return (
    <div className={'element ' + className}>
        <div className={'wrapCreo'}>
        <small className={'upClue'}>{item.orderedGeos.length}</small>
            <TooltipTemplate item={item} tooltipActive={tooltipActive} setTooltipActive={setTooltipActive} />
            <div className={'discriptionCreo'}>
                <div className={'wrapperCreo'}>
                    <div className={'appNameTest'}>Template: {item.template} </div>
                </div>
                <div className={'wrapperCreo'}>
                    <div className={'discription_mainTestBigger'}>CPs: {item.creoNames.length}</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CreoTemplateItem
