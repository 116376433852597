import { React } from 'react'
import './TooltipImage.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import photoImg from '../../img/photo.jpg'


const TooltipImage = ({item}) => {
  
  return (
    <div className={'container'}>
      {item.appImg ?
        <LazyLoadImage
          alt = ''
          className={'img'} 
          src={/*`${process.env.REACT_APP_URL_API}/`*/'https://mathieubois.com/' + item.appImg}
        />
      :
        <LazyLoadImage
          alt = ''
          className={'img'} 
          src={/*`${process.env.REACT_APP_URL_API}/`*/'https://mathieubois.com/' + 'картинка отсутствует.jpg'}
        />
      }
    </div>
  );
}

export default TooltipImage
