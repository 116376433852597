const defaultState = {
    templates: []
}

const RESET_ALL_TEMPLATES = 'RESET_ALL_TEMPLATES'
const ADD_TEMPLATE = 'ADD_TEMPLATE'
const ARRAY_TEMPLATES = 'ARRAY_TEMPLATES'
const DELETE_TEMPLATE = 'DELETE_TEMPLATE'

export const templatesReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_TEMPLATES: 
            return {
                ...state, templates: []
            }
        case ADD_TEMPLATE:
            return {
                ...state, templates: state.templates.concat(action.template)
            }
        case ARRAY_TEMPLATES:
            return { 
                ...state,
                templates: (action.templates).concat(state.templates)
            }
        case DELETE_TEMPLATE:
            return {
                ...state, templates: state.templates.filter(el => el.id != action.id)
            }
        default:
            return state
    }
}
export const resetTemplates = () => ({type: RESET_ALL_TEMPLATES})
export const addTemplate = (template) => ({type: ADD_TEMPLATE, template})
export const addArrayTemplates = (templates) => ({type: ARRAY_TEMPLATES, templates})
export const deleteTemplate = (id) => ({type: DELETE_TEMPLATE, id})