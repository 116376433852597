import {$authHost} from './index.js'

// let offset = 0
// const limit = 200

export const getCreoProfits = async () => {
    const {data} = await $authHost.get('/api/creoProfits/creoProfits')
    return data
}

export const getCreoProfitsByMonth = async (month, year) => {
    // let allData = []

    // while (true) {
        const {data} = await $authHost.get('/api/creoProfits/creoProfitsByMonth/' + month + '/' + year/*, offset, limit*/)
        // const response = await fetch('/api/get-creo-profits', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ month, year, offset, limit }),
        // });

    //     if (!data) {
    //         console.error('Ошибка при загрузке данных')
    //         break;
    //     }

    //     const result = await data.json()

    //     allData = allData.concat(result.data)

    //     if (!result.hasMore) {
    //         break;
    //     }

    //     offset += limit

    //     await new Promise(resolve => setTimeout(resolve, 1000))
    // }
    // console.log('Все данные загружены:', allData);
    
    return data
}