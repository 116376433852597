const defaultState = {
    interactives: []
}

const RESET_ALL_INTERACTIVES = 'RESET_ALL_INTERACTIVES'
const ADD_INTERACTIVE = 'ADD_INTERACTIVE'
const ARRAY_INTERACTIVES = 'ARRAY_INTERACTIVES'

export const interactivesReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_INTERACTIVES: 
            return {
                ...state, interactives: []
            }
        case ADD_INTERACTIVE:
            return {
                ...state, interactives: state.interactives.concat(action.interactive)
            }
        case ARRAY_INTERACTIVES:
            return { 
                ...state,
                interactives: (action.interactives).concat(state.interactives)
            }
        default:
            return state
    }
}
export const resetInteractives = () => ({type: RESET_ALL_INTERACTIVES})
export const addInteractive = (interactive) => ({type: ADD_INTERACTIVE, interactive})
export const addArrayInteractives = (interactives) => ({type: ARRAY_INTERACTIVES, interactives})