const defaultState = {
    filteredTemplates: []
}

const RESET_ALL_TEMPLATES = 'RESET_ALL_TEMPLATES'
const ARRAY_FILTERED_TEMPLATES = 'ARRAY_FILTERED_TEMPLATES'


export const filteredTemplatesReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_TEMPLATES: 
            return {
                ...state, filteredTemplates: []
            }
        case ARRAY_FILTERED_TEMPLATES:
            return { 
                ...state,
                filteredTemplates: (action.filteredTemplates).concat(state.filteredTemplates)
            }
        default:
            return state
    }
}
export const resetFilteredTemplates = () => ({type: RESET_ALL_TEMPLATES})
export const addArrayFilteredTemplates = (filteredTemplates) => ({type: ARRAY_FILTERED_TEMPLATES, filteredTemplates})