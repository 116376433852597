import {$authHost} from './index.js'

export const addNewCreo = async (creoName, template, hashes, refers, duration, formats, file) => {
  const formData = new FormData();
  formData.append('creoName', creoName);
  formData.append('template', template);
  formData.append('hashes', hashes);
  formData.append('refers', refers);
  formData.append('duration', duration);
  formData.append('formats', formats);
  formData.append('file', file);
  const {data} = await $authHost.post('/api/creo/addNewCreo', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

export const getFreeCreos = async (web) => {
  const {data} = await $authHost.get('/api/creo/freeCreos/' + web)
  return data
}

export const getAllCreos = async () => {
  const {data} = await $authHost.get('/api/creo/allCreos')
  return data
}