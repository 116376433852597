import { React } from 'react'
import ReactPlayer from 'react-player'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import './TooltipVideo.css'


const TooltipTemplate = ({item, tooltipActive, setTooltipActive}) => {

  return (
    <LazyLoadComponent>
      <div className={'containerCreo'}
        onClick={() => setTooltipActive(!tooltipActive)}>
        <ReactPlayer
            url={/*`${process.env.REACT_APP_URL_API}/creo/`*/'https://mathieubois.com/creo/' + item.firstFile}
            controls={true}
            loop
            light={'https://mathieubois.com/creo/previews/' + item.firstFile.replace('.mp4', '.jpg')}
            playing
            width='400px'
            height='400px'
            className={'video'}
        />
      </div>
    </LazyLoadComponent>
  );
}

export default TooltipTemplate