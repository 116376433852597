import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'


import { authRoutes, allRoutes } from './routes.js'
import { LOGIN_ROUTE } from '../utils/consts.js'
import PrivateRoute from './PrivateRoute'


const AppRouter = () => {
  const isAuth = useSelector(state => state.auth.isAuth)

  return (
    <Routes>
      {isAuth && authRoutes.map(({ path, Component, allowedRoles }) => (
        <Route
          key={path}
          path={path}
          element={<PrivateRoute element={Component} allowedRoles={allowedRoles} />}
          exact
        />
      ))}
      {/* {isAuth && authRoutes.map(({path, Component}) =>
        <Route key = {path} path = {path} element = {<Component />} exact />
      )} */}
      {allRoutes.map(({path, Component}) => 
        <Route key = {path} path = {path} element = {<Component />} exact />
      )}
      <Route path = "*" element = {<Navigate replace to = {LOGIN_ROUTE} />} />
    </Routes>
  )

}

export default AppRouter