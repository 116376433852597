import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { useTg } from '../hooks/useTg'
import { updateAppScreens, getApps } from '../../http/appsApi'
import './Form.css'

const UpdateAppForm = () => {
    const appsStore = useSelector(state => state.apps.apps)
    const userStore = useSelector(state => state.user.user)
    const [appName, setAppName] = useState('')
    const [newAppName, setNewAppName] = useState('')
    const [appImg, setAppImg] = useState(null)
    const findApp = appsStore.find(app => app.appName === appName)

    const { tg } = useTg()

    // Отправка данных на сервер
    const onSendData = useCallback(() => {
        if (userStore.role === 'Admin' || userStore.role === 'TechHelper') {
            if (!appName || !newAppName || !appImg) {
                alert('Заполни все поля')
                return;
            }

            const findApp = appsStore.find(el => el.appName === appName)
            if (findApp) {
                updateAppScreens(findApp._id, newAppName, appImg).then(data => {
                    if (data) {
                        tg.sendData(JSON.stringify(data))
                        alert(`Приложение ${appName} изменено`)
                    } else {
                        alert('Ошибка')
                    }
                })
            } else {
                alert('Приложение не найдено')
            }
        } else {
            alert('Нет доступа')
        }
    }, [appName, newAppName, appImg, appsStore])

    useEffect(() => {
        getApps()
    }, [])

    const options = appsStore.map(app => ({ label: app.appName, value: app.appName }))

    return (
        <div className="form">
            <h3>Выбери приложение</h3>
            <Select
                className="input"
                options={options}
                onChange={(e) => setAppName(e.value)}
                placeholder="Имя приложения"
            />

            <h3>Новое имя приложения</h3>
            <input
                className="input"
                type="text"
                placeholder="Новое имя приложения"
                value={newAppName}
                onChange={(e) => setNewAppName(e.target.value)}
            />

            <h3>Обновить изображение</h3>
            <input
                type="file"
                onChange={(e) => setAppImg(e.target.files[0])}
                accept="image/*"
                className="input"
            />

            <button className={'webBtnAdd'} onClick={onSendData}>Обновить</button>
        </div>
    );
};

export default UpdateAppForm
