const defaultState = {
    filteredCreoProfits: []
}

const RESET_ALL_FILTERED_CREO_PROFITS = 'RESET_ALL_FILTERED_CREO_PROFITS'
const ARRAY_FILTERED_CREO_PROFITS = 'ARRAY_FILTERED_CREO_PROFITS'

export const filteredCreoProfitsReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_FILTERED_CREO_PROFITS: 
            return {
                ...state, filteredCreoProfits: []
            }
        case ARRAY_FILTERED_CREO_PROFITS:
            return { 
                ...state,
                filteredCreoProfits: action.filteredCreoProfits
            }
        default:
            return state
    }
}

export const resetFilteredCreoProfits = () => ({type: RESET_ALL_FILTERED_CREO_PROFITS})
export const addArrayFilteredCreoProfits = (filteredCreoProfits) => ({type: ARRAY_FILTERED_CREO_PROFITS, filteredCreoProfits})