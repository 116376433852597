const defaultState = {
    filteredCreos: []
}

const RESET_ALL_CREOS = 'RESET_ALL_CREOS'
const FILTERED_CREOS = 'FILTERED_CREOS'

export const filteredCreosReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_CREOS: 
            return {
                ...state, filteredCreos: []
            }
        case FILTERED_CREOS:
            return { 
                ...state,
                filteredCreos: action.filteredCreos
            }
        default:
            return state
    }
}
export const resetFilteredCreos = () => ({type: RESET_ALL_CREOS})
export const addArrayFilteredCreos = (filteredCreos) => ({type: FILTERED_CREOS, filteredCreos})