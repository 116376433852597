const defaultState = {
    creos: []
}

const RESET_ALL_CREOS = 'RESET_ALL_CREOS'
const ADD_CREO = 'ADD_CREO'
const ARRAY_CREOS = 'ARRAY_CREOS'
const DELETE_CREO = 'DELETE_CREO'

export const creosReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_CREOS: 
            return {
                ...state, creos: []
            }
        case ADD_CREO:
            return {
                ...state, creos: state.creos.concat(action.creo)
            }
        case ARRAY_CREOS:
            return { 
                ...state,
                creos: (action.creos).concat(state.creos)
            }
        case DELETE_CREO:
            return {
                ...state, creos: state.creos.filter(el => el.id != action.id)
            }
        default:
            return state
    }
}
export const resetCreos = () => ({type: RESET_ALL_CREOS})
export const addCreo = (creo) => ({type: ADD_CREO, creo})
export const addArrayCreos = (creos) => ({type: ARRAY_CREOS, creos})
export const deleteCreo = (id) => ({type: DELETE_CREO, id})