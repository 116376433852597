import { React, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { addArrayUsers, resetUsers } from '../../store/allUsersReducer'
import { addArrayApps, resetApps } from '../../store/appsReducer'
import { getSources, getAllPartners, addSource, addPartner, addWeb, addWebToApp, removeSource, removePartner, hidePartner, removeWebFromApp } from '../../http/adminApi'
import { getAvailableApps } from '../../http/appsApi'
import { getUsers } from '../../http/usersApi'
import './Form.css'


const AdminForm = () => {
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const appsStore = useSelector(state => state.apps.apps)
    const usersStore = useSelector(state => state.allUsers.allUsers)
    const [appName, setAppName] = useState('')
    const [web, setWeb] = useState('')
    const [availableSources, setAvailableSources] = useState([])
    const [source, setSource] = useState('')
    const [selectedApp, setSelectedApp] = useState({})
    const [sourceName, setSourceName] = useState('')
    const [labelName, setLabelName] = useState('')
    const [partnerName, setPartnerName] = useState('')
    const [naming, setNaming] = useState('')
    const [newUsername, setNewUsername] = useState('')
    const [newWebName, setNewWebName] = useState('')
    const [sourceMode, setSourceMode] = useState('')
    const [sourcesList, setSourcesList] = useState([])
    const [selectedSource, setSelectedSource] = useState('')
    const [partnersList, setPartnersList] = useState([])
    const [selectedPartner, setSelectedPartner] = useState('')
    const [hiddenPartner, setHiddenPartner] = useState('')

    useEffect(() => {
        const getUsersFromDB = async () => {
            await getUsers().then(users => {
                const modifyUsers = users.map(element => {
                    return {
                        value: element.webName,
                        label: element.webName,
                        color: '#000'
                    }
                })
                dispatch(addArrayUsers(modifyUsers))
            })
        }
        if (usersStore.length === 0) {
            getUsersFromDB()
        }
    }, [])


    useEffect(() => {
        const getOptionsFromDB = async () => {
            await getSources().then(sources => {
                const modifySources = sources.map(element => {
                    return {
                        value: element.name,
                        label: element.label,
                        color: '#000'
                    }
                })
                setSourcesList(modifySources)
            })
        }
        getOptionsFromDB()
    }, [])

    useEffect(() => {
        const getOptionsFromDB = async () => {
            await getAllPartners().then(partners => {
                const modifyPartners = partners.map(element => {
                    return {
                        value: element.name,
                        label: element.name,
                        color: '#000'
                    }
                })
                setPartnersList(modifyPartners)
            })
        }
        getOptionsFromDB()
    }, [])

    useEffect( () => {
        dispatch(resetApps())
        getAvailableApps().then(data => {
            if (data.length !== 0) {
                dispatch(addArrayApps(data))
            } 
        })
    }, [])

    useEffect( () => {
        if (appName !== '') {
            const findApp = appsStore.find(app => app.appName === appName)

            const newSources = Object.keys(findApp.sources).map(el => {
                return {
                    value: el,
                    label: el,
                    color: '#000'
                }
            })
            setSelectedApp(findApp)
            setAvailableSources(newSources)
        }
    }, [appName])

    const addNewSource = useCallback(() => {
        addSource(sourceName, labelName, sourceMode).then(data => {
            if (data.message === 'Успешно') {
                alert('Готово')
            } else {
                alert('Ошибка или такой уже есть в базе')
            }
        })
        setSourceName('')
        setLabelName('')
    }, [sourceName, labelName, sourceMode])

    const addNewPartner = useCallback(() => {
        addPartner(partnerName, naming).then(data => {
            if (data.message === 'Успешно') {
                alert('Готово')
            } else {
                alert('Ошибка или такой уже есть в базе')
            }
        })
        setPartnerName('')
        setNaming('')
    }, [partnerName, naming])

    const addNewWeb = useCallback(() => {
        addWeb(newUsername, newWebName).then(data => {
            if (data.message === 'Успешно') {
                alert(`Готово, пароль ${data.newPassword}`)
            } else {
                alert('Ошибка или такой уже есть в базе')
            }
        })
        setNewUsername('')
        setNewWebName('')
    }, [newUsername, newWebName])

    const deleteSource = useCallback(() => {
        removeSource(selectedSource).then(data => {
            if (data.message === 'Успешно') {
                alert('Готово')
            } else {
                alert('Ошибка или такой уже есть в базе')
            }
        })
    }, [selectedSource])

    const deletePartner = useCallback(() => {
        removePartner(selectedPartner).then(data => {
            if (data.message === 'Успешно') {
                alert('Готово')
            } else {
                alert('Ошибка удаления')
            }
        })
    }, [selectedPartner])

    const hidePartnersAndApps = useCallback(() => {
        hidePartner(hiddenPartner).then(data => {
            if (data.message === 'Успешно') {
                alert('Готово')
            } else {
                alert('Ошибка')
            }
        })
    }, [hiddenPartner])

    const addWebToSourceApp = useCallback(() => {
        addWebToApp(selectedApp._id, appName, web, source).then(data => {
            if (data.message === 'Успешно') {
                alert('Готово')
            } else {
                alert('Ошибка')
            }
        })
    }, [source])

    const removeWeb = useCallback(() => {
        removeWebFromApp(selectedApp._id, web, source).then(data => {
            if (data.message.startsWith('Источник')) {
                alert('Готово')
            } else {
                alert('Ошибка')
            }
        })
    }, [source])
    
    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const modeOptions = [
        {value: 'single', label: 'single', color: '#000'},
        {value: 'multi', label: 'multi', color: '#000'},
    ]

    const allApps = appsStore.map(app => ({ ...app, label: app.appName, value: app.appName, color: '#000' }))

    return (
        <div className={'form'}>
            {/* {userStore.role === 'Admin' ?
                <> */}
                    <h3>Добавить источник</h3>
                    <span className={'text'}>
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Имя источника'}
                            value={sourceName}
                            onChange={(e) => setSourceName(e.target.value)}
                        />
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Лейбл источника'}
                            value={labelName}
                            onChange={(e) => setLabelName(e.target.value)}
                        />
                        <Select 
                            styles={colorStyles}
                            menuPortalTarget={document.body}
                            className={'input'} 
                            options={modeOptions} 
                            onChange={(e) => setSourceMode(e.value)}
                            placeholder={'Режим'}
                        />
                    </span>
                    <button className={'webBtnAdd'} onClick={addNewSource}>Добавить источник</button>
                    
                    <h3>Добавить партнера</h3>
                    <span className={'text'}>
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Имя партнера'}
                            value={partnerName}
                            onChange={(e) => setPartnerName(e.target.value)}
                        />
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Нейминг'}
                            value={naming}
                            onChange={(e) => setNaming(e.target.value)}
                        />
                    </span>
                    <button className={'webBtnAdd'} onClick={addNewPartner}>Добавить партнера</button>
    
                    <h3>Добавить Веба</h3>
                    <span className={'text'}>
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Юзернейм тг'}
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                        />
                        <input 
                            className={'input'}
                            type={'text'}
                            placeholder={'Имя веба'}
                            value={newWebName}
                            onChange={(e) => setNewWebName(e.target.value)}
                        />
                    </span>
                    <button className={'webBtnAdd'} onClick={addNewWeb}>Добавить Веба</button>
    
                    <h3>Удалить источник</h3>
                    <span className={'text'}>
                        <Select 
                            styles={colorStyles}
                            menuPortalTarget={document.body}
                            className={'input'} 
                            options={sourcesList} 
                            onChange={(e) => setSelectedSource(e.value)}
                            placeholder={'Источник'}
                        />
                    </span>
                    <button className={'webBtnAdd'} onClick={deleteSource}>Удалить источник</button>
    
                    <h3>Удалить партнера</h3>
                    <span className={'text'}>
                        <Select 
                            styles={colorStyles}
                            menuPortalTarget={document.body}
                            className={'input'} 
                            options={partnersList} 
                            onChange={(e) => setSelectedPartner(e.value)}
                            placeholder={'Партнер'}
                        />
                    </span>
                    <button className={'webBtnAdd'} onClick={deletePartner}>Удалить партнера</button>
    
                    <h3>Скрыть партнера и приложения</h3>
                    <span className={'text'}>
                        <Select 
                            styles={colorStyles}
                            menuPortalTarget={document.body}
                            className={'input'} 
                            options={partnersList} 
                            onChange={(e) => setHiddenPartner(e.value)}
                            placeholder={'Партнер'}
                        />
                    </span>
                    <button className={'webBtnAdd'} onClick={hidePartnersAndApps}>Скрыть партнера и приложения</button>

                    <h3>Закрепить Веба за приложением</h3>
                    <span className={'text'}>
                        <Select 
                            styles={colorStyles}
                            menuPortalTarget={document.body}
                            className={'input'} 
                            options={allApps} 
                            onChange={(e) => setAppName(e.value)} 
                            placeholder={'Имя приложения'} 
                        />
                    </span>

                    <h3>Выбери Веба</h3>
                    <span className={'text'}>
                        <Select 
                            styles={colorStyles} 
                            menuPortalTarget={document.body}
                            className={'options'} 
                            options={usersStore} 
                            onChange={(e) => setWeb(e.value)} 
                        />
                    </span>

                    <h3>Выбери источник</h3>
                    <span className={'text'}>
                        <Select 
                            styles={colorStyles} 
                            menuPortalTarget={document.body}
                            className={'options'} 
                            options={availableSources} 
                            onChange={(e) => setSource(e.value)} 
                        />
                    </span>
                    <button className={'webBtnAdd'} onClick={addWebToSourceApp}>Закрепить</button>
                    <button className={'webBtnAdd'} onClick={removeWeb}>Открепить</button>
                {/* </>
            :
                <h3>Нет доступа</h3>
            } */}
        </div>
    )
}

export default AdminForm

