import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './store/index.js'
import { PersistGate } from 'redux-persist/integration/react'
import Loader from './components/Loader'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store = {store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
