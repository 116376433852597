import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { addArrayApps, resetApps } from '../../store/appsReducer'
import { addArrayUsers, resetUsers } from '../../store/allUsersReducer'
import { getAvailableApps } from '../../http/appsApi'
import { getUsers } from '../../http/usersApi'
import './Statistics.css'


const Statistics = () => {
    const dispatch = useDispatch()
    const appsStore = useSelector(state => state.apps.apps)
    const userStore = useSelector(state => state.user.user)
    const usersStore = useSelector(state => state.allUsers.allUsers)
    const appsInWork = appsStore.filter(el => Object.values(el.sources).flat().length !== 0)
    const [web, setWeb] = useState('')

    useEffect(() => {
        dispatch(resetUsers())
        const getUsersFromDB = async () => {
            await getUsers().then(users => {
                const modifyUsers = users.map(element => {
                    return {
                        value: element.webName,
                        label: element.webName,
                        color: '#000'
                    }
                })
                dispatch(addArrayUsers(modifyUsers))
            })
        }
        getUsersFromDB()
    }, [])

    useEffect( () => {
        dispatch(resetApps())
        getAvailableApps().then(data => {
            if (data.length !== 0) {
                dispatch(addArrayApps(data))
            } 
        })
    }, [])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        }
    }
    
    const mainStats = (id) => {
        switch (id) {
            case '1':
                return appsStore.filter(el => el.platform === 'ios').length
            case '2':
                return appsStore.filter(el => el.platform === 'android').length
            case '3':
                return appsInWork.length
            case '4':
                return appsInWork.filter(el => el.platform === 'ios').length
            case '5':
                return appsInWork.filter(el => el.platform === 'android').length
            default:
                break;
        }
    }

    const appsByWeb = appsInWork.filter(el => Object.values(el.sources).flat().includes(web))


    const findSource = (obj, web) => {
        const entries = Object.entries(obj)
        
        return entries.map((item) => {     
            if (item[1].includes(web)) {
                return `${item[0]} `
            } else {
                return null
            }
        })
    }


    return (
        <div className={'form'}>
            {/* {userStore.role === 'User' || userStore.role === 'CreoUnit' || userStore.role === 'CreoUnitHelper' ?
                <h3>Нет доступа</h3>
            :
                <> */}
                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>Общая статистика</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Всего приложений в боте</td>
                                <td>{appsStore.length}</td>
                            </tr>
                            <tr>
                                <td>IOS</td>
                                <td>{mainStats('1')}</td>
                            </tr>
                            <tr>
                                <td>Android</td>
                                <td>{mainStats('2')}</td>
                            </tr>
                            <tr>
                                <td>Количество приложений в работе</td>
                                <td>{mainStats('3')}</td>
                            </tr>
                            <tr>
                                <td>IOS</td>
                                <td>{mainStats('4')}</td>
                            </tr>
                            <tr>
                                <td>Android</td>
                                <td>{mainStats('5')}</td>
                            </tr>
                        </tbody>
                    </table>


                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>Раскладка по Вебу</th>
                                <th>
                                    <Select styles={colorStyles} className={'options'} options={usersStore} onChange={(e) => setWeb(e.value)} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Всего приложений в работе</td>
                                <td>{appsByWeb.length}</td>
                            </tr>
                            
                        </tbody>
                    </table>


                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>App</th>
                                <th>ID</th>
                                <th>OS</th>
                                <th>Source</th>
                            </tr>
                        </thead>
                        <tbody>
                            {appsByWeb.map(item => {
                                return (
                                    <tr>
                                        <td>{item.appName}</td>
                                        <td>{item.partner}</td>
                                        <td>{item.platform}</td>
                                        <td>{findSource(item.sources, web)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                {/* </>
            } */}
        </div>
    )
}

export default Statistics
