const defaultState = {
    partners: []
}

const RESET_ALL_PARTNERS = 'RESET_ALL_PARTNERS'
const ADD_PARTNER = 'ADD_PARTNER'
const ARRAY_PARTNERS = 'ARRAY_PARTNERS'
const DELETE_PARTNER = 'DELETE_PARTNER'
const UPDATE_PARTNER = 'UPDATE_PARTNER'

export const partnersReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_PARTNERS: 
            return {
                ...state, partners: []
            }
        case ADD_PARTNER:
            return {
                ...state, partners: state.partners.concat(action.partner)
            }
        case ARRAY_PARTNERS:
            return { 
                ...state,
                partners: (action.partners).concat(state.partners)
            }
        case DELETE_PARTNER:
            return {
                ...state, partners: state.partners.filter(el => el != action.partner)
            }
        case UPDATE_PARTNER:
            return {
                ...state, partners: state.partners.map(el => el.id === action.partner.id ? {
                    ...el,
                        partner: action.partner.partner,
                        appName: action.partner.appName,
                        web: action.partner.web,
                        link: action.partner.link
                }
                : el)
            }
        default:
            return state
    }
}
export const resetPartners = () => ({type: RESET_ALL_PARTNERS})
export const addPartner = (partner) => ({type: ADD_PARTNER, partner})
export const addArrayPartners = (partners) => ({type: ARRAY_PARTNERS, partners})
export const deletePartner = (partner) => ({type: DELETE_PARTNER, partner})
export const updatePartner = (partner) => ({type: UPDATE_PARTNER, partner})