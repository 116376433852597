const defaultState = {
    creoProfits: []
}

const RESET_ALL_CREO_PROFITS = 'RESET_ALL_CREO_PROFITS'
const ARRAY_CREO_PROFITS = 'ARRAY_CREO_PROFITS'

export const creoProfitsReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_ALL_CREO_PROFITS: 
            return {
                ...state, creoProfits: []
            }
        case ARRAY_CREO_PROFITS:
            return { 
                ...state,
                creoProfits: (action.creoProfits).concat(state.creoProfits)
            }
        default:
            return state
    }
}

export const resetCreoProfits = () => ({type: RESET_ALL_CREO_PROFITS})
export const addArrayCreoProfits = (creoProfits) => ({type: ARRAY_CREO_PROFITS, creoProfits})