const defaultState = {
    geoPool: []
}

const RESET_GEO_POOL = 'RESET_GEO_POOL'
const ARRAY_GEO_POOL = 'ARRAY_GEO_POOL'

export const geoPoolReducer = ( state = defaultState, action ) => {
    switch (action.type) {
        case RESET_GEO_POOL: 
            return {
                ...state, geoPool: []
            }
        case ARRAY_GEO_POOL:
            return { 
                ...state,
                geoPool: (action.geoPool).concat(state.geoPool)
            }
        default:
            return state
    }
}
export const resetGeoPool = () => ({type: RESET_GEO_POOL})
export const addArrayGeoPool = (geoPool) => ({type: ARRAY_GEO_POOL, geoPool})